import * as React from "react"
import { DefaultLayout } from "../layouts/DefaultLayout"
import { FullWidthComponent } from "../components/FullWidthComponent"
import ReactMarkDown from "react-markdown"
import { sizes } from "../layouts/sizes"
import { useTranslation } from "react-i18next"
import { localizedPage } from "../i18n/Localized"
import TeamContactForm from "../components/ContactForm"

export default localizedPage(() => {
  const { t } = useTranslation()

  return (
    <DefaultLayout title={t("deleteAccount.title")}>
      <FullWidthComponent
        centerStyle={{ margin: sizes.margins.normal, width: "100%" }}
      >
        <ReactMarkDown source={t("deleteAccount.text")} />
      </FullWidthComponent>
    </DefaultLayout>
  )
})
